import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

const mapping = {
    'currencies': 'بازار آزاد ارز',
    'cryptocurrencies': 'ارزهای دیجیتال',
    'gold': 'بازار طلا',
    'forex': 'فارکس',
    'financial-market': 'بورس',
    'energy': 'انرژی',
    'metals': 'فلزات',
    'agriculture': 'بازار کشاورزی',
    'watchlist': 'دیده‌بان',
};

const Breadcrumb = () => {
    const router = useRouter();
    const pathnames = router.asPath.split('/').filter((x) => x);

    return (
        <div>

        <nav
            className="container text-sm absolute top-0 left-0 w-full z-10"
            aria-label="Breadcrumb"
        >
            <ol className="list-none p-0 inline-flex">
                <li>
                    <Link href="/">
                        <a className="text-blue-500">خانه</a>
                    </Link>
                </li>
                {pathnames.map((pathname, index) => {
                    const isLastItem = index === pathnames.length - 1;
                    const name = isLastItem
                        ? mapping[decodeURIComponent(pathname)] || decodeURIComponent(pathname)
                        : mapping[pathname] || pathname;

                    return (
                        <React.Fragment key={pathname}>
                            <li className="mx-2">
                                <span className="text-gray-500">{'>'}</span>
                            </li>
                            <li>
                                {isLastItem ? (
                                    <span className="text-gray-500">{name}</span>
                                ) : (
                                    <Link href={`/${pathnames.slice(0, index + 1).join('/')}`}>
                                        <a className="text-blue-500">{name}</a>
                                    </Link>
                                )}
                            </li>
                        </React.Fragment>
                    );
                })}
            </ol>
        </nav>
        </div>

    );
};

export default Breadcrumb;
