import React from "react";
import SmallPriceBox from "./smallPriceBox/smallPriceBox";
import MiddleBox from "./MiddleBox";
import HeaderAbBox from "./../ads/HeaderAdBox";
import LeftBox from "./LeftBox";
import Heading from "./Heading";
import Breadcrumb from "../plugins/Breadcrumb";

const Box = (props) =>
    <>
        {/*{props.disableHeaderAdBox === undefined && <HeaderAbBox/>}*/}
        {props.heading && <Heading text={props.heading}/>}
        <section id="box-container" dir="rtl"
             className={`${props.fullContainer ? 'full-container' : "container"} ${props.isMainPage ? 'pt-2' : 'pt-4'} position-relative flex-display`}
             style={{height: props.height ? props.height : 'auto'}}>
            {!props.isMainPage && <Breadcrumb />}
            {props.showRW && <SmallPriceBox {...props}/>}
            <MiddleBox {...props}/>
            {/*{props.disableAdBox === undefined && <LeftBox {...props}/>}*/}
        </section>
        {/*<BoxStyle />*/}
    </>;

export default Box;