import create from 'zustand'
import {persist} from "zustand/middleware"

export const useIranStockMarket = create(persist(
    (set, get) => ({
        isMarketTime: null,
        setIsMarketTime: (marketTime) => set({isMarketTime: marketTime}),
    }),
    {
        name: "iran-market-storage",
    }))