import React, {useState} from 'react'
import BoxHtmlLoader from './home-page/BoxHtmlLoader';
import {LazyLoadImage} from 'react-lazy-load-image-component';

const ImageLoadedChecker = ({src, alt, className, width, height}) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div className={`row position-relative ${!imageLoaded && 'm-0'}`}>
            <LazyLoadImage
                // importance={"auto"}
                width={width}
                height={height}
                src={src}
                alt={alt ? alt : ''}
                className={`${className} col`}
                afterLoad={() => setImageLoaded(true)}
            />
            {!imageLoaded && <BoxHtmlLoader width={width} height={height} className={className}/>}
        </div>
    );
}

export default ImageLoadedChecker;