import React from "react";

export default function Heading({text}) {
    return (
        <section>
            <h1 className='container my-3 text-center persian-font' style={{
                fontSize: '1.4em',
                fontWeight: 600,
                color: '#616161 '
            }}>{text}</h1>
        </section>
    );
}