import React, {useState} from 'react';
import Autosuggest from 'react-autosuggest';

export default function AutoSuggest({data, onChangeHandler, inputPlace = 'نماد'}) {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const getSuggestions = value => {
        const inputValue = value;
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : data.filter(lang =>
            lang.slice(0, inputLength) === inputValue
        );
    };

    const renderSuggestion = suggestion => (
        <div>{suggestion}</div>
    );

    const onSuggestionsFetchRequested = ({value, reason}) => {
        setSuggestions(getSuggestions(value))
        if (reason === 'suggestion-selected') onSuggestionsClearRequested();
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
        setValue('')
    };

    const inputProps = {
        placeholder: inputPlace,
        value,
        onChange: (event, {newValue}) => {
            setValue(newValue)
        }
    };

    return (
        <>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                alwaysRenderSuggestions={true}
                // focusInputOnSuggestionClick={false}
                getSuggestionValue={x => {
                    onChangeHandler(x)
                    return x
                }}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />

            <style>{`
              .react-autosuggest__container {
                position: relative;
              }

              #box-container .react-autosuggest__input {
                //width: px;
                text-align: right;
                border-radius: 5px;
                height: 28px;
                background-color: white;
                border: 1px solid #dfe1e5;
                padding: 10px 20px;
                font-size: 14px;
                -webkit-appearance: none;
              }

              .react-autosuggest__input--focused {
                outline: none;
              }

              .react-autosuggest__input::-ms-clear {
                display: none;
              }

              .react-autosuggest__input--open {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }

              .react-autosuggest__suggestions-container {
                display: none;
              }

              #box-container  .react-autosuggest__suggestions-container--open {
                display: block;
                direction: rtl;
                width: 218px;
                font-size: 14px;
                position: absolute;
                margin: 0;
                z-index: 1000;
                max-height: 250px;
                overflow-y: auto;
                padding: 0;
                background-color: white;
                text-align: right;
                list-style: none;
                border: 1px solid #dfe1e5;
                font-weight: 400;
                line-height: 1.7;
                color: #2D3747;
                cursor: pointer;
                border-top: none;
                border-radius: 0 0 12px 12px;
                top: 28px;
              }

              .react-autosuggest__suggestions-list {
                margin: 0;
                padding: 0;
                list-style-type: none;
              }

              .react-autosuggest__suggestion {
                cursor: pointer;
                padding: 5px 20px;
              }

              .react-autosuggest__suggestion--highlighted {
                background-color: #ddd;
              }
              
            //.react-autosuggest__suggestions-list
            //{
            //  max-height: 200px;
            //  overflow-y: scroll;
            //}
            `}</style>

        </>
    );
}