export default function SmallPriceBoxStyle() {

    return (
        <style type="text/css">{`
            .rw {
                line-height: 1.3;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            
            .rwCon {
                overflow-x: hidden;
            }
            
            .rwRow {
                margin-bottom: 5px;
                padding: 14px;
            }
            
            .rwHeader {
                height: 48px;
                font-size: 14px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                background-color: #F4F4F6;
            }
            
            .rwTblHead {
                font-size: 12px;
                height: 22px;
                background-color: #F4F4F6 !important;
            }
            
            .rwTblHead span {
                padding: 0 14px;
            }
            
            .rwSymbol {
                font-size: 14px;
                padding: 14px;
            }
            
            .rwPrice {
                font-size: 11px;
                font-weight: 500;
                direction: ltr;
            }
            
            .rwLast {
                font-weight: 600;
                font-size: 14px;
            }
            
            .rwChanges {
                margin-left: 5px;
                display: inherit;
            }
            
            .rw-item:hover {
                background-color: #e3e5e8;
            }
            
            .rwLoader {
                box-shadow: none;
            }
            `}
        </style>
    );
}
