import React from "react";

export default function ExtraMenuStyle() {

    return (
        <style type="text/css">{`
                .form-check-input {
                    margin-right: -1rem;
                    margin-left: -1.25rem;
                }

                    .rw-widget-container, .rw-rtl .rw-select-bordered {
                    border: none;
                }

                    .rw-combobox input {
                    padding-right: 1.7rem;
                }

                    .rw-state-focus + .fa-search {
                    display: block;
                }

                    #extraMenu {
                    top: 12px;
                    right: 0;
                    z-index: 111;
                }

                    .MuiInputLabel-formControl {
                    left: 83% !important;
                }

                    .exteraMenuContainer {
                    top: 0 !important;
                    background-color: rgba(255, 255, 255, 0.9);

                }

                    .hideMenu {
                    width: 0;
                    height: 0;
                    opacity: 0;
                }

                    .dropdown-toggle:after {
                    float: left;
                    margin-top: 0.5rem;
                    margin-left: -3px;
                }

                    .dropdown-menu {
                    width: 100%;
                    text-align: right;
                }

                    .searchInput {
                    padding-right: 32px;
                }

                    #forCloseExtraMenu {
                    top: 0;
                }

                    .rc-slider-mark {
                    top: 16px !important;
                    font-size: 11px;
                }

                    .MuiTypography-body1 {
                    font-size: 9px !important;
                }`}
        </style>
    );
}
