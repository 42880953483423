import React from 'react'
import './LiveCircle.module.css'
import {useIranStockMarket} from "../store/IranStockMarket";

export default function LiveCircle(props) {
    const isMarketTime = useIranStockMarket(state => state.isMarketTime)

    return (
        <>
            {isMarketTime &&
                <span className="live-circle"/>
            }
        </>
    )
}