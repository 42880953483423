import React from "react";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';

const BoxLoader = ({showMenu = true, contentHeight, className = "middle-box-content"}) =>
    <div style={{lineHeight: 0, float: className === 'middle-box-content' && 'left'}} className={className}>
        {showMenu &&
        <div style={{marginBottom: '2px'}}>
            <SkeletonTheme color="#e0e0e0">
                <Skeleton height={38} duration={1}/>
            </SkeletonTheme>
        </div>
        }بهابین
        <div style={{marginBottom: '2px', position: 'relative'}}>
            <SkeletonTheme color="#bdbdbd">
                <Skeleton height={!!contentHeight ? contentHeight : 500} duration={1}/>
            </SkeletonTheme>
            <img
                style={{position: 'absolute', top: '44%', left: '42%', width: '150px'}}
                alt="Bahabin"
                src='/images/bahabin-persian-big-white.png'
            />
        </div>
    </div>


export default BoxLoader;