import React from "react";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';

const RightWatchlistLoader = () =>
    <div>
        <div>
            <SkeletonTheme color="#e0e0e0">
                <Skeleton height={38} duration={1} style={{lineHeight: 'inherit'}}/>
                <div className="rw-margin" style={{marginTop: 0, marginBottom: 0, lineHeight: 2}}>
                    <Skeleton duration={1} height={25}/>
                </div>
            </SkeletonTheme>
        </div>
        <div className="rw-margin" style={{marginTop: 0, lineHeight: 2.5}} >
            <SkeletonTheme color="#bdbdbd">
                <Skeleton count={15} duration={1} height={51}/>
            </SkeletonTheme>
        </div>
    </div>


export default RightWatchlistLoader;