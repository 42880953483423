import React, {useEffect, useState} from "react";
import {ExtraMenu} from "./extramenu/ExtraMenu";
import BoxLoader from "../loaders/BoxLoader";
import {getJoyRide, useWindowSize} from "../../util/DominantUtil";
import BoxLoader401 from "../loaders/notAuthenticated/BoxLoader401";
import {isMobile} from "react-device-detect";

function makeAutoScrollBorderClassName(props) {
    if (!!props && props.disableBorder) return ""
    return "bordered"
}

const MiddleBox = (props) => {
        const [borderClassName, setBorderClassName] = useState(makeAutoScrollBorderClassName(props));
        const {scaledHeight} = useWindowSize()

        useEffect(() => {
            if (props.uiActions && props.uiActions.enableAutoScrollBorder) setBorderClassName("purple-border")
            else setBorderClassName("right-bordered")
        }, [props.uiActions && props.uiActions.enableAutoScrollBorder])

        if (props && props.uiActions)
            props.uiActions.enableAutoScrollBorderHandler = function (enabled) {
                setBorderClassName(enabled ?
                    "purple-border" :
                    makeAutoScrollBorderClassName(props)
                );
            }

        function getTitle() {
            return <div className="row m-0 left-box-cols"
                        style={{
                            fontSize: "1.0rem",
                            height: "40px",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px",
                            paddingTop: "3px",
                            backgroundColor: '#F4F4F6'
                        }}
            >
                <div className={`text-right ${isMobile ? 'col-12' : 'col-3'} ${props.titleColor ? props.titleColor : ''}`}>
                    <h2 className="d-inline-block persian-font"
                        style={{fontSize: props.titleWidth ? '0.75em' : '1em', marginTop: '6px'}}>
                        {props.title}
                    </h2>
                </div>
                {props.uiActions && <ExtraMenu uiActions={props.uiActions}/>}
            </div>;
        }

        return (
            <div className='w-100'>
                {props.notAuthenticated ?
                    <BoxLoader401 showMenu={props.showMenu} contentHeight={scaledHeight}/>
                    : props.loaded ?
                        <div
                            className={`middle-box-content bordered-radius ${props.leftBoxClassName} ${borderClassName}`}
                            style={{height: props.height ? props.height : 'auto'}}
                        >
                            {!props.disableHeader &&
                            getTitle()
                            }
                            {/*{getJoyRide(props.tooltipSteps)}*/}
                            {props.children}
                        </div>
                        :
                        <BoxLoader showMenu={props.showMenu} contentHeight={scaledHeight}/>
                }
            </div>
        );
    }
;

export default MiddleBox;
