import React, {useEffect} from 'react'
import EachSmallPriceBox from "./eachSmallItem";
import {useWindowSize} from "../../../util/DominantUtil";
import {useIranFinancialMarketGlobalState} from "../../store/IranFinancialMarketGlobalState";

export default function SmallPriceBox(props) {
    const [financialMarketTopItems, refreshFinancialMarketList] = useIranFinancialMarketGlobalState(state => [state.topItems, state.refreshTopItems]);

    useEffect(() => {
        refreshFinancialMarketList();
    }, [])

    return (
        <div style={{width: '20%'}}>
            <EachSmallPriceBox data={financialMarketTopItems?.data} title={'بیشترین معاملات'} market={'financial-market'}/>
        </div>
    )
}