import create from 'zustand'
import {refreshStateDataForIranMarkets} from "./StoreUtil";

const addr = 'https://api.bahabin.com/quotes?market=IRFM';
const topItemsAddr = 'https://api.bahabin.com/quotes?market=IRFM&limit=10&sort_by=v&order_by=desc';

export const useIranFinancialMarketGlobalState = create((set, get) => ({
  financialMarketList: null,
  topItems: null,
  setFinancialMarketList: (financialMarketList) => set({ financialMarketList }),
  setTopItems: (topItems) => set({ topItems }),
  refreshFinancialMarketList: async () =>
    await refreshStateDataForIranMarkets(() => get().financialMarketList, get().setFinancialMarketList, addr, get),
  refreshTopItems: async () =>
    await refreshStateDataForIranMarkets(() => get().topItems, get().setTopItems, topItemsAddr, get),
}));