import React from 'react'
import {getColor, getSignWithPercent} from "../../../util/DominantUtil";
import Link from "next/link";
import SmallPriceBoxStyle from './smallPriceBoxStyle'
import RightWatchlistLoader from "../../loaders/RightWatchlistLoader";

export default function EachSmallPriceBox({data, title, className, market}) {
    function getSymbol(symbol, last, lastChange, lastChangePercent) {
        return <div className={`row bg-white low-shadow bordered-radius rwRow`}>
            <div className={`col text-right rwSymbol my-auto`}>
                <Link href={`/${market}/${symbol}`}><a>{symbol}</a></Link>
            </div>
            <div className="col text-left my-auto">
                <div>
                    <span className='rwLast'>{last}</span>
                </div>
                <div className="rwPrice">
                    <div className="row">
                        <div className="col">
                            <span
                                className={`rwChanges ${getColor(lastChange)}`}>{getSignWithPercent(lastChange)}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span
                                className={`rwChanges ltr ${getColor(lastChangePercent)}`}>{getSignWithPercent(lastChangePercent)}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    return (
        <>
            {data ?
                <div className="right-bordered ml-1.5 right-watchlist-content rw rwCon">
                    <div className="rwMargin">

                        <section
                            className={className}>
                            <header className={`text-right rwHeader pr-3`}>
                                <div style={{paddingTop: '10px'}}>
                                    <a className='text-dark' href={`/${market}/watchlist`} title="My Index"
                                       data-test="list-name">
                                        {title}
                                    </a>
                                </div>
                            </header>
                            <div className={`rwTblHead d-flex justify-content-between`}>
                                <span>نماد</span>
                                <span>آخرین</span>
                            </div>
                            <SmallPriceBoxStyle/>
                            {data && data.map(x => getSymbol(x.faN ?? x.s, x.c, x.p, x.P))}
                        </section>
                    </div>
                </div>
                :
                <div className='side-box'>
                    {/*style={{height: props.height ? props.height : minHeight}}>*/}
                    <RightWatchlistLoader/>
                </div>
            }
        </>
    )
}