import React from "react";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import Router from "next/router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BoxLoader401 = ({
                          showMenu = true,
                          contentHeight
                      }) =>
    <div className='w-full leading-none'>
        {showMenu && <div style={{marginBottom: '2px'}}>
            <SkeletonTheme color="#e0e0e0" highlightColor="#bdbdbd">
                <Skeleton height={38} duration={5}/>
            </SkeletonTheme>
        </div>
        }
        <div style={{marginBottom: '2px', position: 'relative'}}
             className='cursor-pointer'
             onClick={function () {
                 Router.push("/login")
             }}>
            <SkeletonTheme color="#bdbdbd" highlightColor="#9e9e9e">
                <Skeleton height={!!contentHeight ? contentHeight : 500} duration={5}/>
            </SkeletonTheme>
            <div
                style={{position: 'absolute', top: '46%', left: '49%', width: '150px'}}
                className="faa-parent"
            >
                <FontAwesomeIcon className="cursor-pointer fa-lg faa-ring animated text-gray-600"
                                 icon='lock'/>
            </div>
        </div>
    </div>

export default BoxLoader401;