import React, {useEffect, useMemo, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import fa from 'timeago.js/lib/lang/fa'
import moment from 'jalali-moment'
import Switch from "react-switch";
import LiveCircle from "../../liveCircle/LiveCircle";
import ExtraMenuStyle from "./ExtramenuStyle";
import AutoSuggest from "../../dropdown/AutoSuggest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

timeago.register('fa', fa);

// const theme = createMuiTheme({
//     direction: "rtl", // Both here and <body dir="rtl">
// });

export function ExtraMenu({uiActions}) {

    const [searchValue, setSearchValue] = useState(null);
    const [counter, setCounter] = useState(0);
    const [checked, setChecked] = useState(true);
    const [checkedGics, setCheckedGics] = useState(false);
    const [checkedScroll, setCheckedScroll] = useState(false);
    const [checkedDark, setCheckedDark] = useState(false);
    const [enableAutoScroll, setEnableAutoScroll] = useState(false);
    const [sectors, setSectors] = useState(null);
    const [clearSectorsTextBox, setClearSectorsTextBox] = useState(false)

    const filterUIProps = useMemo(() => {
        return {
            columns: uiActions.columns,
            setColumns: uiActions.setColumns,
            setQueryParams: uiActions.setQueryParams,
            queryParams: uiActions.queryParams,
            lastUpdate: uiActions.lastUpdate,
            handleSearch: uiActions.handleSearch,
            dropDownHandler: uiActions.dropDownHandler,
            comboboxData: uiActions.comboboxData,
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setEnableAutoScroll(uiActions.enableAutoScrollButton)
        setCheckedScroll(uiActions.checkedScroll)
        setCheckedDark(uiActions.checkedDark)
    }, [uiActions.enableAutoScrollButton, uiActions.checkedScroll, uiActions.checkedDark])

    const [show, setToggle] = React.useState(false);
    const someName = useRef(null);

    //select combobox
    function onSelect(e) {
        uiActions.dropDownHandler(e);
        setToggle(false);
    }

    //clear search input
    function clearSearch() {
        someName.current.value = ''
        filterUIProps.handleSearch('')
    }

    //toggle extra menu
    function handleToggleExtra() {
        setToggle(!show)
        setCounter(1)
    }

    function valueVolumeToggleHandler() {
        setChecked(!checked);
        uiActions.handleSwitchValueVolume(checked);
    }

    function gicsToggleHandler() {
        setCheckedGics(!checkedGics);
        uiActions.handleSwitchSectorGICS(checkedGics);
        setEnableAutoScroll(false)
        if (uiActions.switchAutoScroll) scrollToggleHandler(false)
        setClearSectorsTextBox(!clearSectorsTextBox)
    }

    function scrollToggleHandler(useState = true) {
        setCheckedScroll(useState && !checkedScroll);
        uiActions.handleSwitchAutoScroll(useState && !checkedScroll);
        uiActions.enableAutoScrollBorderHandler(useState && !checkedScroll)
    }

    function darkToggleHandler(useState = true) {
        setCheckedDark(useState && !checkedDark);
        uiActions.handleSwitchDark(useState && !checkedDark)
    }

    // if (!!uiActions.comboboxData) setSectors(uiActions.comboboxData)

    return (
        <div className="text-left col-9" style={{paddingTop: '2px'}}>
            <Row className="" dir="ltr">
                {/* time ago (lastupdate) */}
                {uiActions.lastUpdate &&
                <div className="col-3 extra-last-update text-left pl-3">
                    {uiActions.disableLiveCircle ? '' : <LiveCircle/>}
                    {uiActions.lastUpdate &&
                    <div style={{fontSize: "0.8em", marginTop: "3px"}}>
                        <span>{moment(new Date(uiActions.lastUpdate)).locale('fa').format('HH:mm:ss')}</span>
                        <span style={{fontSize: '11px', marginLeft: '5px'}}>(<TimeAgo dir="rtl"
                                                                                      datetime={uiActions.lastUpdate}
                                                                                      locale="fa"/>)</span>
                    </div>
                    }
                </div>
                }

                {/*trends time frames*/}
                {
                    uiActions.marks &&
                    <Col xs={4} className="extra-trends-slider bg-white text-dark round"
                         style={{height: "18px", borderRadius: '10px'}}>
                        <div className="d-flex" dir="rtl" style={{marginTop: "2px"}}>
                            <FontAwesomeIcon
                                className="text-secondary ml-2.5"
                                icon='check-square'/>
                            <Slider
                                className="mr-1"
                                min={0}
                                defaultValue={0}
                                onChange={uiActions.valueLabelFormat}
                                marks={uiActions.marks} step={null}/>
                        </div>
                    </Col>
                }

                {/*category/sector combobox*/}
                {!!uiActions.sectors && <div className="col-5 extraDropDown extra-sectors-dropdown pl-3">
                    <AutoSuggest data={uiActions.sectors} inputPlace={uiActions.dropDownInputPlace}
                                 onChangeHandler={(data) => {
                                     uiActions.dropDownHandler(data);
                                     setEnableAutoScroll(true);
                                 }}/>
                </div>
                }

                {!!uiActions.switchValueVolume &&
                <Col className="extraValueVolumeToggle extra-switch-btn pl-2" style={{padding: "3px 8px"}}>
                    <Switch
                        checked={checked}
                        onChange={valueVolumeToggleHandler}
                        height={20}
                        width={50}
                        onColor="#81D4FA"
                        onHandleColor="#039BE5"
                        handleDiameter={26}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        uncheckedIcon={
                            <div style={{position: "absolute", right: "2px", top: "2px"}}>
                                <FontAwesomeIcon style={{position: "absolute", right: "5px"}}
                                                 className="text-light"
                                                 icon='dollar-sign'/>
                            </div>
                        }
                        checkedIcon={
                            <div style={{position: "inherit", top: "2px"}}>
                                <FontAwesomeIcon style={{position: "absolute", right: "5px"}}
                                                 className="text-light"
                                                 icon='poll'/>
                            </div>
                        }
                    />
                </Col>

                }

                {!!uiActions.switchGics &&
                <div className="extraGicsToggle extra-switch-btn" style={{padding: "3px 8px"}}>

                    <Switch
                        checked={checkedGics}
                        onChange={gicsToggleHandler}
                        height={20}
                        width={50}
                        onColor="#C5E1A5"
                        onHandleColor="#7CB342"
                        handleDiameter={26}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        uncheckedIcon={
                            <div style={{position: "absolute", right: "2px", top: "2px"}}>
                                <FontAwesomeIcon style={{position: "absolute", right: "5px"}}
                                                 className="text-light"
                                                 icon='globe'/>
                            </div>
                        }
                        checkedIcon={
                            <div style={{position: "inherit", top: "2px"}}>
                                <FontAwesomeIcon style={{position: "absolute", right: "5px"}}
                                                 className="text-gray"
                                                 icon='street-view'/>
                            </div>
                        }
                    />
                </div>
                }

                {!!uiActions.switchAutoScroll &&
                <div className="col-2 extraAutoScroll extra-switch-btn pl-3" style={{padding: "3px 8px"}}>
                    <Switch
                        checked={checkedScroll}
                        onChange={scrollToggleHandler}
                        height={20}
                        disabled={!enableAutoScroll}
                        width={50}
                        onColor="#ffcc80"
                        onHandleColor="#fb8c00"
                        handleDiameter={26}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        uncheckedIcon={
                            <div style={{position: "absolute", right: "0px", top: "2px"}}>
                                <FontAwesomeIcon style={{position: "absolute", right: "5px"}}
                                                 className="text-light"
                                                 icon='sync'/>
                            </div>
                        }
                        checkedIcon={
                            <div style={{position: "absolute", right: "0px", top: "2px"}}>
                                <FontAwesomeIcon style={{position: "absolute", right: "5px"}}
                                                 className="text-light"
                                                 icon='table'/>
                            </div>
                        }
                    />
                </div>
                }

                {!!uiActions.switchDarkBackground &&
                <div className="col-2 extraDarkTheme extra-switch-btn pl-3" style={{padding: "3px 8px"}}>
                    <Switch
                        checked={checkedDark}
                        onChange={darkToggleHandler}
                        height={20}
                        width={50}
                        onColor="#757575"
                        onHandleColor="#757575"
                        handleDiameter={26}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        uncheckedIcon={
                            <div style={{position: "absolute", right: "0px", top: "2px"}}>
                                <FontAwesomeIcon style={{position: "absolute", right: "5px"}}
                                                 className="text-light"
                                                 icon='moon'/>
                            </div>
                        }
                        checkedIcon={
                            <div style={{position: "absolute", right: "0px", top: "2px"}}>
                                <FontAwesomeIcon style={{position: "absolute", right: "5px"}}
                                                 className="text-light"
                                                 icon='sun'/>
                            </div>
                        }
                    />
                </div>
                }
            </Row>

            <ExtraMenuStyle/>
        </div>
        // </ThemeProvider>
    );
}
